import React, { useDebugValue, useState } from 'react';
import logo from './logo.svg';
import ContactPhoneTwoToneIcon from '@mui/icons-material/ContactPhoneTwoTone';
import './App.css';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { CircularProgress, Button, TextField } from '@mui/material';


const DisplayData = ({ data }) => {

  if (data === null) {
    return (<div></div>)
  } else {
    let dateString = '';

    if (data.date) {
      dateString = new Date(data.date).toDateString();
    }
    return (
      <div className="display-container">
        <h3>Result: </h3>
        {data && data.date ? (<span>{'This is a cached result retrieved on: ' + dateString}</span>) : (<div></div>)}
        {
          Object.keys(data.result).map((key, index) => {
            if (typeof data.result[key] !== 'object') {
              return (
                <div className={"result-container"}>
                  <h4 className={"result-key"} key={key}>{key}:</h4>
                  <div className={"result-value"}>{JSON.stringify(data.result[key])}</div>
                </div>
              )
            } else {
              const keyResult = data.result[key];
              console.log('Hi Jon', keyResult)
              if (keyResult != null) {
                return Object.keys(keyResult).flatMap((key2, index2) => {
                  return (
                    <div className={"result-container"}>
                      <h4 className={"result-key"} key={key2}>{key2}:</h4>
                      <div className={"result-value"}>{JSON.stringify(keyResult[key2])}</div>
                    </div>
                  )
                })
              }
            }
          })
        }
      </div>
    )
  }
}

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [isError, setIsError] = useState(false);
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
  const onSubmit = data => {
    console.log('form data', data)
    callApi(data.number);
  };

  const resetForm = () => {
    reset({ number: ""});
    setApiResponse(null);
    setIsError(false);
    setIsLoading(false);
  }

  console.log(errors);
  const callApi = (number: string) => {
    setIsLoading(true);
    axios.get(`https://us-central1-phone-lookup-fb.cloudfunctions.net/getPhoneDetails?number=${number}`)
      .then(res => {
        console.log(res)
        setApiResponse(res.data as any);
        setIsLoading(false);
      })
      .catch(e => {
        setIsError(true);
        setApiResponse(e);
        setIsLoading(false);
      })
  };
  return (
    <div className="App" style={{ backgroundColor: isError ? 'pink' : 'white' }}>
      <ContactPhoneTwoToneIcon color="primary" className="App-logo" sx={{ fontSize: 100 }}/>
      <h1>Free Number Lookup</h1>

      {isLoading ? <CircularProgress /> : (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="number"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField type="tel" id="outlined-basic" label="Phone Number" variant="outlined" {...field} />}
            />
            <Button type="submit" disabled={apiResponse} variant="contained">Search</Button>
          </form>
          {apiResponse && <Button variant="contained" onClick={resetForm}>Reset</Button>}
        </div>
      )}

      {/* {JSON.stringify(apiResponse)} */}
      {(apiResponse && !isError) && (
        <DisplayData
          data={apiResponse}
        />)}
      {isError && (
        <div>
          <h2>
            Something went wrong...
          </h2>
        </div>
      )}

    </div>
  );
}

export default App;
