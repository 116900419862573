import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously, createUserWithEmailAndPassword } from "firebase/auth";
// import * as firebase from 'firebase';
// import * as firebaseui from 'firebaseui';



// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
var firebaseConfig = {
  apiKey: "AIzaSyBnESCAeM_TFtEmIyB1_WErQiUiGeFxE2Y",
  authDomain: "phone-lookup-fb.firebaseapp.com",
  databaseURL: "https://phone-lookup-fb-default-rtdb.firebaseio.com",
  projectId: "phone-lookup-fb",
  storageBucket: "phone-lookup-fb.appspot.com",
  messagingSenderId: "540703736695",
  appId: "1:540703736695:web:bd82cebf3f5aa59fad1772",
  // measurementId: "G-MEASUREMENT_ID",
};


const app = initializeApp(firebaseConfig);

const auth = getAuth();


//anon sign in
signInAnonymously(auth)
  .then(() => {
    // Signed in..
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });

//email sign in 
// createUserWithEmailAndPassword(auth, email, password)
//   .then((userCredential) => {
//     // Signed in 
//     const user = userCredential.user;
//     ReactDOM.render(
//             <React.StrictMode>
//               <App />
//             </React.StrictMode>,
//             document.getElementById('root')
//           );
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // ..
//   });



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
